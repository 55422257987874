import React, { useEffect, useState } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import fetch from '@okta/okta-auth-js/lib/fetch/fetchRequest';
import AuthContext from '../../contexts/authContext';
import config from '../../config';
import TagManager from '../../TagManager';
import { toggleUserInfo } from '../../helpers/index';

const Authentication = (props) => {
  const { children, oktaAuth, authState } = props;
  const { token: { decode, getUserInfo } } = oktaAuth;
  const { isAuthenticated, accessToken: { value: accessTokenValue } } = authState;
  const userClaims = decode(accessTokenValue);

  const getUserData = async () => {
    const response = await getUserInfo();
    if (response) {
      toggleUserInfo(response);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    let managedTokens = localStorage.getItem('managed-tokens') || '[]';
    managedTokens = JSON.parse(managedTokens);

    if (!Array.isArray(managedTokens)) {
      localStorage.setItem('managed-tokens', '[]');
    } else {
      managedTokens.push(authState);
      localStorage.setItem('managed-tokens', JSON.stringify(managedTokens));
    }
  }, [authState]);

  const logout = async () => {
    localStorage.setItem('isAuthenticated', false);
    oktaAuth.signOut('/');
  };

  const revokeAuthToken = () => {
    const bodyItems = [];
    bodyItems.push(`token=${accessTokenValue}`);
    bodyItems.push('token_type_hint=refresh_token');
    // bodyItems.push(`token_type_hint=access_token`);
    bodyItems.push(`client_id=${config.okta.client_id}`);
    fetch('POST', `${config.okta.issuer}/v1/revoke`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: bodyItems.join('&'),
    })
  };

  const _revokeToken = (authToken) => {
    authToken = JSON.parse(authToken);
    authToken = authToken?.accessToken?.accessToken;

    const bodyItems = [];
    bodyItems.push(`token=${authToken}`);
    bodyItems.push('token_type_hint=refresh_token');
    // bodyItems.push(`token_type_hint=access_token`);
    bodyItems.push(`client_id=${config.okta.client_id}`);
    fetch('POST', `${config.okta.issuer}/v1/revoke`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: bodyItems.join('&'),
    });
  };

  const revokeManagedTokens = () => {
    try {
      const promises = [];
      let managedTokens = localStorage.getItem('managed-tokens');

      if (!managedTokens) {
        return Promise.resolve({});
      }

      managedTokens = JSON.parse(managedTokens);

      if (Array.isArray(managedTokens)) {
        for (let i = 0; i < managedTokens.length; i++) {
          const authToken = managedTokens[i];
          if (authToken) {
            promises.push(_revokeToken(authToken));
          }
        }
      }

      localStorage.setItem('managed-tokens', '[]');
    } catch (err) {
      // handle error
    }
  };

  const revokeToken = async () => {
    try {
      revokeManagedTokens();
      revokeAuthToken();
      logout();
    } catch (error) {
    //
    }
  }

  const initialState = {
    auth: { ...authState, ...oktaAuth },
    customLogout: revokeToken,
    authenticated: isAuthenticated,
    token: accessTokenValue,
    userId: userClaims && userClaims.payload && userClaims.payload.uid,
    userClaims,
  };
  const [value,] = useState(initialState);

  localStorage.setItem('isAuthenticated', isAuthenticated);
  return (
    <AuthContext.Provider value={value}>
      <TagManager authConfig={value}>
        {children}
      </TagManager>
    </AuthContext.Provider>
  );
};

Authentication.propTypes = {
  auth: PropTypes.object,
  children: PropTypes.object,
};

export default withOktaAuth(Authentication);
